require('./3.6.1.jquery.min.js');
require('./1.13.1.jquery-ui.min.js');
require('./jquery.inputmask.min.js');

import IMask from 'imask';

$(function (){
  $("body").on("click", "#menu-close",function(){
    document.getElementById("bar").style.width = "0";
  });

  $("body").on("click", "#menu-open",function(){
    document.getElementById("bar").style.width = "280px";
  });

  if (typeof popupData !== 'undefined') {
    let popup = $('<div id="popup"></div>');
    popup.append('<span>' + popupData.message + '</span>');
    $('body').append(popup);

    $( "#popup" ).dialog({
      modal: true,
      draggable: false,
      resizable: false,
      width: '90%',
      dialogClass: popupData.status,
      title: popupData.title,
      buttons: {
        Rendben: function() {
          $( this ).dialog( "close" );
          $( "#popup" ).remove();
        }
      }
    });
  }

  if (typeof confirmPopupData !== 'undefined') {
    let confirm_popup = $('<div id="confirm-popup"></div>');
    confirm_popup.append('<span>' + confirmPopupData.message + '</span>');
    $('body').append(confirm_popup);

    $( "#confirm-popup" ).dialog({
      modal: true,
      draggable: false,
      resizable: false,
      width: '90%',
      dialogClass: confirmPopupData.status,
      title: confirmPopupData.title,
      buttons: {
        Igen: function() {
          $( this ).dialog( "close" );
          $( "#confirm-popup" ).remove();
          window.location.href = confirmPopupData.confirm_url;
        },
        Mégsem: function() {
          $( this ).dialog( "close" );
          $( "#confirm-popup" ).remove();
        }
      }
    });
  }

  $(":input").inputmask();
});

window.enableBtn = function () {
  $('#SubmitButton').prop('disabled', false);
}

window.disableBtn = function () {
  $('#SubmitButton').prop('disabled', true);
}